import React from 'react';
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function NotFound() {
  return (
   <Layout>
      <div className='page-not-found'>
        <div>
          <p className='icon'>?</p>
        </div>
        <div className='message'>
          <h1>Page not found</h1>
          <p>We couldn't find the page you're looking for. It's possible the page moved or there's a typo in your URL.</p>
          <p>Start at the <Link to='/'>home page</Link> and use the site navigation to find the page you're looking for.
          </p>
        </div>
      </div>
  </Layout>
  )
}
